import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import profileImage from "../images/mikaellundin.webp"

export default function Bio({ className }) {
  const data = useStaticQuery(graphql`
    query Bio {
      markdownRemark(fileAbsolutePath: { regex: "/bio.md$/g" }) {
        html
      }
    }
  `)

  const { markdownRemark } = data
  const { html } = markdownRemark
  return (
    <section id="bio" className={`${className} always-page-break-after`}>
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 p-4 print:max-w-none print:flex print:flex-row print:justify-items-start print:p-2">
        <img
          className="rounded-full w-96 justify-self-center self-center shadow-2xl print:rounded-none print:w-40 print:shadow-md print:self-start"
          src={profileImage}
          alt="Mikael Lundin Profile"
        />
        <article
          dangerouslySetInnerHTML={{ __html: html }}
          className="mx-4"
        ></article>
      </div>
    </section>
  )
}
