import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CodeIcon from "../icons/codeIcon"
import ProductIcon from "../icons/productIcon"
import ArchitectIcon from "../icons/architectIcon"
import ProjectIcon from "../icons/projectIcon"

function Role({ role }) {
  switch (role) {
    case "Software Developer":
      return (
        <div className="block float-none lg:float-left">
          <CodeIcon className="float-left mr-1 h-6" color="white" />
          <span className="mr-2">{role}</span>
        </div>
      )
    case "Data Engineer":
      return (
        <div className="block float-none lg:float-left">
          <CodeIcon className="float-left mr-1 h-6" color="white" />
          <span className="mr-2">{role}</span>
        </div>
      )
    case "Solution Architect":
      return (
        <div className="block float-none lg:float-left">
          <ArchitectIcon className="float-left mr-1 h-6" color="white" />
          <span className="mr-2">{role}</span>
        </div>
      )
    case "Project Manager":
      return (
        <div className="block float-none lg:float-left">
          <ProjectIcon className="float-left mr-1 h-6" color="white" />
          <span className="mr-2">{role}</span>
        </div>
      )
    case "Product Owner":
      return (
        <div className="block float-none lg:float-left">
          <ProductIcon className="float-left mr-1 h-6" color="white" />
          <span className="mr-2">{role}</span>
        </div>
      )
    default:
      return <span className="float-left mr-2">{role}</span>
  }
}

export default function Experience({ className }) {
  const data = useStaticQuery(graphql`
    query Experience {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/experience//" } }
        sort: { fields: fileAbsolutePath, order: DESC }
      ) {
        edges {
          node {
            html
            frontmatter {
              title
              client
              duration
              tags
              roles
            }
          }
        }
      }
    }
  `)
  const { allMarkdownRemark } = data
  const { edges } = allMarkdownRemark

  return (
    <section
      id="experience"
      className={`${className} text-white py-4 print:always-page-break-before`}
    >
      <h1 className="text-center print:text-left">Experience</h1>

      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 mx-8 p-4 lg:px-0 print:mx-0 print:p-0 print:block print:max-w-none">
        {edges.map((edge, i) => (
          <article
            key={i}
            className="flex flex-col bg-green-600 p-8 border-2 border-green-700 rounded-lg shadow-xl print:border-0 print:p-0 print:shadow-none avoid-page-break-after print:clear-left print:border-b-2 print:mb-8 print:rounded-none print:pb-8 avoid-page-break-after"
          >
            <h2>{edge?.node?.frontmatter?.title}</h2>
            <span className="order-first font-regular uppercase text-base">
              {edge?.node?.frontmatter?.client ?? ""}, <wbr />
              <span className="whitespace-nowrap">
                {edge?.node?.frontmatter?.duration}
              </span>
            </span>

            <div
              className=" text-lg"
              dangerouslySetInnerHTML={{ __html: edge.node.html }}
            ></div>
            <div className="my-4 align-middle font-regular text-base">
              {(edge?.node?.frontmatter?.roles ?? []).map((role, j) => (
                <Role key={j} role={role} />
              ))}
            </div>
            <ul className="avoid-page-break-inside">
              {(edge?.node?.frontmatter?.tags ?? []).map((tag, j) => (
                <li
                  key={j}
                  className="bg-green-400 py-2 px-4 float-left font-regular text-sm mr-2 my-1 rounded-lg print:border-2 print:border-gray-800 print:py-1 print:px-2 print:text-xs print:mr-2 print:my-1"
                >
                  {tag}
                </li>
              ))}
            </ul>
          </article>
        ))}
      </div>
    </section>
  )
}
